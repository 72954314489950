:root {
    --text-color: rgb(255, 237, 237);
    --background-color: rgb(30, 26, 32);
    --accent-color: rgb(49, 225, 247);
}

* {
    margin: 0;
    padding: 0;
}

html {
    background: var(--background-color);
}

body {
    font: 115%/1.5 'Nunito', "Helvetica", "Arial", sans-serif;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    outline: 0;
    position: relative;
	-webkit-text-stroke: 1px var(--accent-color);
    color: transparent;
}

a::before {
	left: auto;
    right: 0;
    width: 0;
    direction: rtl;
    color: var(--text-color);
    overflow: hidden;
    position: absolute;
    content: attr(data-text);
    -webkit-transition: all .4s;
    transition: all .4s;
	border-bottom: 1px dotted var(--text-color);
}

a:hover::before {
	width: 100%;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    width: 50%;
    color: var(--text-color);
    margin-left: 20%;
}
@media (max-width: 800px) {
	.section {
		margin-left: 10%;
		margin-right: 10%;
		width: 80%;
	}
}

.section .heading {
    font-size: 5vmin;
    margin: 0.67em 0;
}

.section .heading .shadowed-text {
    text-shadow: 2px 0 0 var(--accent-color);
}

.section .about ul {
	margin-left: 30px;
}

.section .networks-list {
    margin-top: 1.5rem;
    padding: 0;
    list-style: none;
    font-size: 90%;
	width: 33.33%;
	display: flex;
	justify-content: flex-start;
	gap: 10px;
}

.section .networks-list .network {
    text-transform: uppercase;
}